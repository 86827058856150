export const getUserRoleNameByLevel = function(level){
  if(level === 10){
    return 'Alumno'
  }

  if(level === 20){
    return 'Profesor'
  }

  if(level === 60) {
    return 'Publicador'
  }

  if(level === 70) {
    return 'Coordinador'
  }

  if(level >= 90) {
    return 'Administrador'
  }

  return 'Público'
}

export const getUserBgByLevel = function(level){
  if(level > 20){
    return 'light-grey'
  }

  return 'white';
}
