<template>
  <div
    v-if="user"
    class="school-method-group-user-item mb-2"
  >
    <div>
      <span class="mr-2">{{ user.fullname }}</span>
      <b-tag
        class="mr-2"
        type="is-black"
      >
        {{ getUserRoleNameByLevel(user.level) }}
      </b-tag>
      <template v-for="(otherGroup, index) in user.school_groups">
        <b-tag
          v-if="mustShowOtherGroup(otherGroup)"
          :key="otherGroup.id"
          type="is-primary is-light"
          :class="{'mr-1': index !== (user.school_groups.length - 1)}"
        >
          {{ otherGroup.title }}
        </b-tag>
      </template>
    </div>
    <div class="is-size-7">
      Última actividad: {{ user.last_activity | prettyDate }}
    </div>
  </div>
</template>

<script>
import {getUserRoleNameByLevel} from "@/utils/user";

export default {
  name: "SchoolMethodGroupUserListItem",
  props: {
    user: {
      type: Object,
      default: () => null
    },
    currentGroup: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    getUserRoleNameByLevel,
    mustShowOtherGroup(otherGroup){
      if(this.user && this.user.level >= 20){
        return false
      }
      if(!this.currentGroup){
        return false
      }

      return parseInt(this.currentGroup.id) !== parseInt(otherGroup.id)
    }
  }
}
</script>

<style scoped lang="scss">
.school-method-group-user-item {
  display: flex;
  justify-content: space-between;

  &:last-child{
    margin-bottom: 0 !important;
  }
}
</style>
