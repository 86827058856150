<template>
  <b-modal
    v-model="showModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    @close="close"
  >
    <template #default="props">
      <div
        v-if="data"
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body">
          <h4 class="title mb-2">
            {{ data.title }}
          </h4>
          <div class="mb-1">
            Recibido el
            <b-tag
              class="mr-1"
              type="is-info"
            >
              {{ sendedAt }}
            </b-tag>
          </div>
          <div
            v-if="openedAt"
            class="mb-1"
          >
            Abierto el
            <b-tag
              v-if="openedAt"
              class="mr-1"
              type="is-warning"
            >
              {{ openedAt }}
            </b-tag>
          </div>
          <div class="content my-2">
            {{ data.body }}
          </div>
          <b-button
            v-if="data.link"
            type="is-black"
            size="is-small"
            @click="openContent"
          >
            <b-icon
              class="mr-1"
              icon="link"
            /> Ir al contenido
          </b-button>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Volver"
            @click="close"
          />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "NotificationModal",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: function () {
    return {
      showModal: this.show
    }
  },
  computed: {
    sendedAt() {
      if (!this.data) {
        return null
      }
      const sendedAt = dayjs(this.data.sended_at)
      return sendedAt.format('DD/MM/YYYY HH:MM')
    },
    openedAt() {
      if (!this.data || !this.data.opened_at) {
        return null
      }
      const openedAt = dayjs(this.data.opened_at)
      return openedAt.format('DD/MM/YYYY HH:MM')
    }
  },
  watch: {
    value: function (val) {
      this.content = val
    },
    show: function (val) {
      this.showModal = val
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async openContent(){
      if(!this.data.link){
        return ''
      }

      try{
        await this.$router.push(this.data.link)
      }catch (e) {
        console.error('error abriendo enlace al contenido')
      }
    }
  }
}
</script>
