<template>
  <div>
    <h4 class="is-size-4 mb-2">
      Cumpleaños
    </h4>
    <div class="notifications-container">
      <user-birthday-list-item
        v-for="birthday in birthdays"
        :key="birthday.id"
        :data="birthday"
        @click="openBirthday"
      />
      <div class="my-3 is-flex is-justify-content-end">
        <b-button
          class="is-primary"
          @click="openNewBirthday"
        >
          Añadir
        </b-button>
      </div>
    </div>
    <div class="is-size-7 py-2 has-text-grey has-text-centered">
      La fecha de cumpleaños y nombre se usarán exclusivamente para enviar una felicitación en el día del cumpleaños.
    </div>
    <user-birthday-modal
      :show="showModal"
      :data="selectedBirthday"
      @close="closeBirthday"
    />
  </div>
</template>

<script>
import UserBirthdayListItem from "@/components/lists/UserBirthdayListItem.vue";
import UserBirthdayModal from "@/components/modals/UserBirthdayModal.vue";
import dayjs from "dayjs";
const BASE_BIRTHDAY = {
  title: '',
  datedAt: '01/01',
  day: 1,
  month: 0
}

export default {
  name: "UserBirthdays",
  components: {UserBirthdayModal, UserBirthdayListItem},
  data(){
    return {
      birthdays: [],
      selectedBirthday: {...BASE_BIRTHDAY},
      showModal: false
    }
  },
  beforeMount() {
    this.loadBirthdays()
  },
  methods: {
    async loadBirthdays(){
      try{
        const birthdays = await this.$api.user.loadBirthdays()

        this.birthdays = birthdays.map((item) => {
          const datedAt = dayjs(item.dated_at)
          item.month = datedAt.month()
          item.day = datedAt.date()

          return item
        })
      }catch (e) {
        this.$msg.error(e.message)
      }
    },
    openBirthday(selected){
      this.selectedBirthday = selected
      this.showModal = true
    },
    openNewBirthday(){
      this.selectedBirthday = {...BASE_BIRTHDAY}
      this.showModal = true
    },
    closeBirthday(event){
      this.showModal = false
      this.selectedNotification = {...BASE_BIRTHDAY}
      if(event?.result === true){
        this.loadBirthdays()
      }
    }
  }
}
</script>
