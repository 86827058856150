<template>
  <div>
    <h4 class="is-size-4 mb-2">
      Próximos cumpleaños
    </h4>
    <label>
      <span class="mb-2">Filtrar por grupos</span>
      <multiselect
        v-model="filters.groupIds"
        :options="groups"
        placeholder="Escribe para buscar"
        track-by="id"
        label="title"
        :multiple="true"
        :deselect-label="multiselectConfig.deselectLabel"
        :select-label="multiselectConfig.selectLabel"
        :allow-empty="true"
      >
        <template slot="noResult">No se ha encontrado ningún elemento, prueba con una búsqueda diferente.</template>
        <template slot="noOptions">No hay grupos</template>
      </multiselect>
    </label>
    <div class="notifications-container">
      <div
        v-if="!hasBirthdays"
        class="has-text-weight-bold has-text-centered py-2"
      >
        No hay próximos cumpleaños
      </div>
      <user-next-birthday-list-item
        v-for="birthday in filteredBirthdays"
        :key="birthday.id"
        :data="birthday"
        @click="openBirthday"
      />
      <template v-if="hasRecentBirthdays">
        <h4 class="my-3 has-background-blue p-2 has-text-weight-bold is-rounded">
          Cumpleaños recientes
        </h4>
        <user-next-birthday-list-item
          v-for="birthday in filteredRecentBirthdays"
          :key="birthday.id"
          :data="birthday"
          @click="openBirthday"
        />
      </template>
    </div>
  </div>
</template>

<script>
import UserNextBirthdayListItem from "@/components/lists/UserNextBirthdayListItem";
import dayjs from "dayjs";
import 'dayjs/locale/es'
import {merge, intersection, isEqual, uniqWith, cloneDeep} from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  name: "UserNextBirthdays",
  components: {UserNextBirthdayListItem, Multiselect},
  data() {
    return {
      birthdays: [],
      recentBirthdays: [],
      filters: {
        groupIds: []
      },
      multiselectConfig: {
        selectLabel: 'Pulsa intro para seleccionar',
        deselectLabel: 'Pulsa intro para deseleccionar',
        selectedLabel: 'Seleccionado',
        limit: 10
      },
      groups: []
    }
  },
  computed: {
    hasBirthdays(){
      return this.filteredBirthdays.length > 0
    },
    filteredBirthdays() {
      let filtered = cloneDeep(this.birthdays)

      if (this.filters.groupIds.length > 0) {
        filtered = filtered.filter(birthday => {
          return intersection(birthday.user.groups.map(item => item.id), this.filters.groupIds.map(item => item.id)).length > 0
        })
      }

      return filtered
    },
    filteredRecentBirthdays() {
      let filtered = cloneDeep(this.recentBirthdays)

      if (this.filters.groupIds.length > 0) {
        filtered = filtered.filter(birthday => {
          return intersection(birthday.user.groups.map(item => item.id), this.filters.groupIds.map(item => item.id)).length > 0
        })
      }

      return filtered
    },
    hasRecentBirthdays(){
      return this.filteredRecentBirthdays.length > 0
    }
  },
  async beforeMount() {
    await this.loadNextBirthdays()
    await this.loadRecentBirthdays()
    this.groups = this.getSelectableGroups(this.birthdays, this.recentBirthdays)
  },
  methods: {
    async loadNextBirthdays() {
      try {
        this.filters.from = dayjs().startOf('day')
        this.filters.to = dayjs().endOf('day').add(1, 'week')

        const fromFilter = this.filters.from.format('YYYY-MM-DD HH:mm:ss')
        const toFilter = this.filters.to.format('YYYY-MM-DD HH:mm:ss')

        const birthdays = await this.$api.user.loadNextBirthdays(fromFilter, toFilter)

        this.birthdays = birthdays
      } catch (e) {
        this.$msg.error(e.message)
      }
    },
    async loadRecentBirthdays() {
      try {
        this.filters.from = dayjs().startOf('day').subtract(1, 'day').subtract(1, 'week')
        this.filters.to = dayjs().subtract(1, 'day').endOf('day')

        const fromFilter = this.filters.from.format('YYYY-MM-DD HH:mm:ss')
        const toFilter = this.filters.to.format('YYYY-MM-DD HH:mm:ss')

        const birthdays = await this.$api.user.loadNextBirthdays(fromFilter, toFilter)

        this.recentBirthdays = birthdays
      } catch (e) {
        this.$msg.error(e.message)
      }
    },
    getSelectableGroups(birthdays, recentBirthdays) {
      const groups = []

      birthdays.forEach((birthday) => {
        merge(groups, birthday.user.groups)
      })

      recentBirthdays.forEach((birthday) => {
        merge(groups, birthday.user.groups)
      })

      return uniqWith(groups, isEqual)
    },
    async openBirthday(selected) {
      try {
        await this.$router.push(`/?happyBirthday=${selected.title}`)
      } catch (e) {
        console.error('no se ha podido abrir la felicitación')
      }
    }
  }
}
</script>

<style scoped>
.is-rounded{
  border-radius: 10px;
}
</style>
