<template>
  <div
    v-if="data"
    class="list-item separator has-background-white column is-full py-2 px-5"
    @click="handleClick"
  >
    <div class="columns is-mobile is-vcentered">
      <div class="column is-10 is-size-7">
        <h4 class="has-text-weight-bold mb-2">
          <b-icon
            v-if="!data.opened_at"
            class="has-text-red"
            icon="thumbtack"
          />
          <div>
            <div class="is-size-6">
              {{ data.title }}
            </div>
            <div class="is-size-7 has-text-grey has-text-weight-light">
              {{ data.sended_at | prettyDate }}
            </div>
          </div>
        </h4>
      </div>
      <div class="column is-2 has-text-right">
        <b-icon
          icon="angle-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from '@/utils/image'

export default {
  name: "NotificationListItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.data)
    }
  }
}
</script>
