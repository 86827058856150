<template>
  <div>
    <h4 class="is-size-4 mb-2">
      Notificaciones
    </h4>
    <div class="notifications-container">
      <notification-list-item
        v-for="notification in notifications"
        :key="notification.id"
        :data="notification"
        @click="openNotification"
      />
      <div class="is-size-7 py-2 has-text-grey has-text-centered">
        Notificaciones recibidas en los últimos 30 días
      </div>
    </div>
    <notification-modal
      :show="showNotificationModal"
      :data="selectedNotification"
      @close="closeNotification"
    />
  </div>
</template>

<script>
import NotificationListItem from "@/components/lists/NotificationListItem";
import NotificationModal from "@/components/modals/NotificationModal";
export default {
  name: "UserNotifications",
  components: {NotificationModal, NotificationListItem},
  data(){
    return {
      showNotificationModal: false,
      selectedNotification: null
    }
  },
  computed:{
    notifications(){
      return this.$store.state.notifications
    }
  },
  methods: {
    openNotification(data){
      this.selectedNotification = data
      this.showNotificationModal = true

      if(!data.opened_at){
       this.markAsReaded(data.id)
      }
    },
    async markAsReaded(notificationId){
      try{
        const when = new Date()
        await this.$api.notification.markAsReaded(notificationId)
        this.$store.commit('markNotificationAsReaded',{id: notificationId, opened_at: when})
      }catch (e) {
        console.error(e)
      }
    },
    closeNotification(){
      this.showNotificationModal = false
      this.selectedNotification = null
    }
  }
}
</script>
