<template>
  <ul class="legal-footer">
    <li>
      <a
        href="#"
        @click.prevent="openLegalPage('legal-advice')"
      >
        Aviso legal
      </a>
    </li>
    <li>
      <a
        href="#"
        @click.prevent="openLegalPage('privacy-policy')"
      >
        Política de privacidad
      </a>
    </li>
    <li>
      <a
        href="#"
        @click.prevent="openLegalPage('cookies-policy')"
      >
        Política de cookies
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LegalFooter",
  methods: {
    async openLegalPage(routeName) {
      try {
        await this.$router.push({name: routeName})
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.legal-footer {
  display: flex;
  justify-content: center;

  li {
    margin-right: 1rem;
    &:last-child{
      margin-right: 0;
    }

    a {
      font-size: 10px;
      text-decoration: none;
    }
  }
}
</style>
