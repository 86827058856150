<template>
  <div
    v-if="data"
    class="list-item separator has-background-white column is-full py-2 px-5"
    @click="handleClick"
  >
    <div class="columns is-mobile is-vcentered">
      <div class="column is-9 is-size-5">
        <div class="mb-2">
          <h4>
            <b-icon
              class="has-text-red mr-2"
              icon="gift"
            />{{ data.title }}<span class="is-size-7"> ({{ data.user.fullname }})</span>
          </h4>
          <b-tag
            v-if="getIsToday(data.dated_at)"
            type="is-danger is-light"
          >
            HOY
          </b-tag>
          <b-tag
            v-if="getIsTomorrow(data.dated_at)"
            type="is-warning is-light"
          >
            MAÑANA
          </b-tag>
        </div>
      </div>

      <div class="column is-3 has-text-right">
        <div class="has-text-weight-bold">
          {{ getLabel(data.dated_at) }}
        </div>
      </div>
    </div>
    <div class="is-size-12">
      <b-tag
        v-for="group in data.user.groups"
        :key="group.id"
        class="mr-1 mb-1"
        type="is-primary is-light"
      >
        {{ group.title }}
      </b-tag>
    </div>
  </div>
</template>

<script>

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";

dayjs.extend(relativeTime)

dayjs.locale('es')
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

export default {
  name: "UserNextBirthdayListItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.data)
    },
    getLabel(datedAt) {
      const now = dayjs()
      let birthdayAt = dayjs(datedAt)
      birthdayAt = birthdayAt.year(now.year())

      return birthdayAt.locale('es').format('D [de] MMMM')
    },
    getIsToday(when) {
      const now = dayjs()
      const whenDate = dayjs(when).year(now.year())
      return whenDate.isToday()
    },
    getIsTomorrow(when) {
      const now = dayjs()
      const whenDate = dayjs(when).year(now.year())
      return whenDate.isTomorrow()
    },
    getRelativeLabel(datedAt) {
      const now = dayjs().startOf('day')
      let birthdayAt = dayjs(datedAt)
      birthdayAt = birthdayAt.year(now.year())

      if (birthdayAt.isBefore(now)) {
        birthdayAt = birthdayAt.add(1, 'year')
      }

      return birthdayAt.locale('es').from(now)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.multiselect {
  .multiselect__tags{
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  }
}
</style>
