<template>
  <b-modal
    v-model="showModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    @close="close"
  >
    <template #default="props">
      <div
        v-if="data"
        class="modal-card"
        style="width: auto"
      >
        <section class="modal-card-body">
          <h4 class="title mb-2">
            {{ modalTitle }}
          </h4>
          <div>
            <b-field>
              <b-input
                ref="title"
                v-model="localData.title"
                type="text"
                placeholder="Nombre"
                required
                maxlength="100"
                minlength="2"
              />
            </b-field>
          </div>
          <div>
            <b-field>
              <b-select
                v-model="localData.day"
                expanded
                placeholder="día"
                required
              >
                <option
                  v-for="day in monthDays"
                  :key="day"
                  :value="day"
                >
                  {{ day }}
                </option>
              </b-select>
              <b-select
                v-model="localData.month"
                expanded
                placeholder="mes"
                required
              >
                <option
                  v-for="month in months"
                  :key="month.value"
                  :value="month.value"
                >
                  {{ month.label }}
                </option>
              </b-select>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-space-between">
          <b-button
            label="Volver"
            @click="close"
          />
          <div>
            <b-button
              v-if="isEditing"
              type="is-danger"
              label="Borrar"
              :disabled="isSending"
              @click="deleteBirthdayConfirm"
            />
            <b-button
              type="is-success"
              :disabled="isInvalidForm || isSending"
              @click="saveBirthday"
            >
              {{ buttonLabel }}
            </b-button>
          </div>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "UserBirthdayModal",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: function () {
    return {
      isSending: false,
      showModal: this.show,
      monthDays: [1],
      months: [
        {label: 'Enero', value: 0},
        {label: 'Febrero', value: 1},
        {label: 'Marzo', value: 2},
        {label: 'Abril', value: 3},
        {label: 'Mayo', value: 4},
        {label: 'Junio', value: 5},
        {label: 'Julio', value: 6},
        {label: 'Agosto', value: 7},
        {label: 'Septiembre', value: 8},
        {label: 'Octubre', value: 9},
        {label: 'Noviembre', value: 10},
        {label: 'Diciembre', value: 11}
      ],
      localData: {
        title: '',
        datedAt: '',
        month: 0,
        day: 1
      }
    }
  },
  computed: {
    modalTitle() {
      if (!this.isEditing) {
        return 'Nuevo cumpleaños'
      }

      return this.data.title
    },
    buttonLabel() {
      if (!this.isEditing) {
        return 'Crear'
      }

      return 'Guardar'
    },
    isInvalidForm(){
      return this.localData.title === '' || this.localData.month === '' || this.localData.day === ''
    },
    isEditing() {
      return !!this.localData?.id
    }
  },
  watch: {
    show: function (val) {
      this.showModal = val
      if (this.showModal === true) {
        this.selectFirstField()
      }
    },
    data: function (val) {
      this.localData = val
      this.updatedSelectedMonth(this.localData.month)
    },
    'localData.month': function (val) {
      this.updatedSelectedMonth(val)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    selectFirstField() {
      setTimeout(() => {
        try {
          this.$refs.title.focus()
        } catch (e) {
          console.error('No se ha podido seleccionar el primer campo')
        }
      }, 300)
    },
    updatedSelectedMonth(month) {
      let selectedMonth = dayjs()
      selectedMonth = selectedMonth.month(month)
      const daysCount = selectedMonth.daysInMonth()

      const monthDays = []
      for (let day = 1; day <= daysCount; day++) {
        monthDays.push(day)
      }

      this.monthDays = monthDays
    },
    async saveBirthday() {
      if(this.isInvalidForm){
        return
      }

      try {
        this.isSending = true
        const dayStr = ('0' + this.localData.day).slice(-2)
        const monthStr = ('0' + (this.localData.month+1)).slice(-2)

        const data = {
          title: this.localData.title,
          dated_at: `2000-${monthStr}-${dayStr} 00:00:00`
        }

        if (!this.isEditing) {
          await this.$api.user.addBirthday(data)
        }else{
          await this.$api.user.updateBirthday(this.localData.id, data)
        }

        this.$msg.show('Cumpleaños añadido')

        this.$emit('close',{result: true})
        this.isSending = false
      } catch (e) {
        this.isSending = false
        this.$msg.error(e.message)
      }
    },
    async deleteBirthdayConfirm(){
      try {
        this.isSending = true
        await this.$api.user.deleteBirthday(this.localData.id)

        this.$msg.show('Cumpleaños borrado')

        this.$emit('close',{result: true})
        this.isSending = false
      } catch (e) {
        this.isSending = false
        this.$msg.error(e.message)
      }
    }
  }
}
</script>
