<template>
  <div>
    <h4 class="is-size-4 mb-2">
      Grupos
    </h4>
    <div class="school-method-groups-container">
      <b-collapse
        v-for="(group, index) of groups"
        :key="index"
        class="card"
        animation="slide"
        :open="isOpen === index"
        :aria-id="'contentIdForA11y5-' + index"
        @open="isOpen = index"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            :aria-controls="'contentIdForA11y5-' + index"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              {{ group.title }}
              <b-tag
                class="ml-2"
                type="is-primary"
              >
                {{ group.method_abbr }}
              </b-tag>
            </p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'caret-up' : 'caret-down'"
              />
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <p
              v-if="group.description"
              class="mb-3 has-background-grey-lighter p-4 is-rounded"
            >
              {{ group.description }}
            </p>
            <div class="school-method-group-users-container">
              <template v-for="user in group.users">
                <school-method-group-user-list-item
                  v-if="user.level < 20"
                  :key="user.id"
                  :user="user"
                  :current-group="group"
                />
              </template>
            </div>
            <div class="school-method-group-managers-container mt-2">
              <template v-for="user in group.users">
                <school-method-group-user-list-item
                  v-if="user.level >= 20"
                  :key="user.id"
                  :user="user"
                  :current-group="group"
                />
              </template>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import SchoolMethodGroupUserListItem from "@/components/lists/SchoolMethodGroupUserListItem.vue";

export default {
  name: "UserSchoolMethodGroups",
  components: {SchoolMethodGroupUserListItem},
  data() {
    return {
      isOpen: null,
      groups: []
    }
  },
  beforeMount() {
    this.loadGroups()
  },
  methods: {
    async loadGroups() {
      try {
        const groups = await this.$api.user.loadSchoolMethodGroups()

        this.groups = groups.map((group) => {
          group.users = this.getSortedUsers(group.users)
          return group
        })
      } catch (e) {
        this.$msg.error(e.message)
      }
    },
    getSortedUsers(users) {
      users.sort(this.compareUsers)
      return users
    },
    compareUsers(a, b) {
      if (a.level < b.level) {
        return -1;
      }
      if (a.level > b.level) {
        return 1;
      }
      return 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.is-rounded {
  border-radius: 10px;
}
.school-method-group-users-container{
  padding: 10px;
}
.school-method-group-managers-container{
  background-color: lightgray;
  padding: 10px;
  border-radius: 6px;
}
</style>
