<template>
  <div>
    <h4 class="is-size-4 mb-2">
      Mis códigos
    </h4>
    <b-table
      v-if="hasCodes"
      :data="codes"
      :columns="codesColumns"
      :paginated="true"
      :current-page="1"
      :per-page="3"
    />
    <div
      v-if="!hasCodes"
      class="has-text-centered my-4 has-text-weight-bold"
    >
      No hay códigos asociados
    </div>
    <b-button
      type="is-secondary is-uppercase mb-3 mt-3"
      expanded
      @click="goToAssociateCode"
    >
      Añadir códigos
    </b-button>
  </div>
</template>

<script>
export default {
  name: "UserMethodCodes",
  props: {
    codes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data(){
    return {
      codesColumns: [
        {
          field: 'code',
          label: 'Código'
        },
        {
          field: 'used_at',
          label: 'Fecha asociación',
        },
        {
          field: 'expires_at',
          label: 'Fecha expiración',
        },
        {
          field: 'method_title',
          label: 'Método'
        }
      ]
    }
  },
  computed: {
    hasCodes(){
      return this.codes && this.codes.length > 0
    }
  },
  methods: {
    async goToAssociateCode() {
      try {
        await this.$router.push('associate-code')
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
