<template>
  <div
    v-if="data"
    class="list-item separator has-background-white column is-full py-2 px-5"
    @click="handleClick"
  >
    <div class="columns is-mobile is-vcentered">
      <div class="column is-9 is-size-5">
        <h4 class="mb-2">
          {{ data.title }}
        </h4>
      </div>
      <div class="column is-3 has-text-right">
        <div class="has-text-weight-bold">
          {{ getLabel(data.dated_at) }}
        </div>
        <div class="is-size-6">
          {{ getRelativeLabel(data.dated_at) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
dayjs.extend(relativeTime)

export default {
  name: "UserBirthdayListItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.data)
    },
    getLabel(datedAt){
      const now = dayjs()
      let birthdayAt = dayjs(datedAt)
      birthdayAt = birthdayAt.year(now.year())

      return birthdayAt.locale('es').format('D [de] MMMM')
    },
    getRelativeLabel(datedAt){
      const now = dayjs().startOf('day')
      let birthdayAt = dayjs(datedAt)
      birthdayAt = birthdayAt.year(now.year())

      if(birthdayAt.isBefore(now)){
        birthdayAt = birthdayAt.add(1,'year')
      }

      return birthdayAt.locale('es').from(now)
    }
  }
}
</script>
